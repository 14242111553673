<template>
  <div>
    date:<v-datetime-picker dateFormat="dd-MM-yyyy" label="Select Datetime" v-model="datetime"> </v-datetime-picker>
    <!-- <datetime type="datetime" v-model="datetime" format="yyyy-MM-dd HH:mm:ss"></datetime> -->
    <v-sheet
      tile
      height="54"
      color="grey lighten-3"
      class="d-flex"
    >
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        label="type"
      ></v-select>
      <v-select
        v-model="mode"
        :items="modes"
        dense
        outlined
        hide-details
        label="event-overlap-mode"
        class="ma-2"
      ></v-select>
      <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="weekdays"
        class="ma-2"
      ></v-select>
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="getEvents"
        @click:event="showEvent"
        @click:day="getdata"
      ></v-calendar>
    </v-sheet>
    <v-btn
        icon
        class="ma-2"
        @click="dateTime"
      >Curr</v-btn>
      {{datetime}}
      {{events}}
  </div>
</template>
<script>
import axios from "axios";
  export default {
    data: () => ({
      datetime:null,
      type: 'month',
      types: ['month', 'week', 'day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      ],
      value: '',
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }),
    methods: {
      dateTime(){
        let dt = this.formatDate(this.datetime,true)
        const formData={
          datetime:dt
        }
        console.log(formData)
         axios.post("tryNError/dateTime",formData)
    .then(res =>{
      if(res.data.msg=="200"){
    
      }
      else{
      }

    })
    .catch(error => {
        window.console.log(error)
    })
      },
         showEvent ({ nativeEvent, event }) {
             alert(event.name)
         },
        getdata({day}){
            alert(day)
        },
        getEventData(start){
            return start==this.value
        },
      getEvents () {
          alert(this.value)

        //   this.events = []
        //    this.events.push({
        //     name: "vikas",
        //     start: this.formatDate(new Date(), !true),
        //     end: this.formatDate(new Date(), !true),
        //     color: "red",
        // })
      },
       getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      formatDate (a, withTime) {
        return withTime
          ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
          : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
      },
    },
    mounted(){
        axios.get("tryNError/calendar")
    .then(res =>{
      if(res.data.msg=="200"){
      this.events.push({
            name: "vikas",
            start: res.data.date,//this.formatDate(res.data.date, !true),
            end: res.data.date,//this.formatDate(res.data.date, !true),
            color: "red",
        })
      }
      else{
      }

    })
    .catch(error => {
        window.console.log(error)
    })
        
        
    }
  }
</script>